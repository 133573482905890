.sub #subcontainer .Diamond, .Platinum, .VIP {
   font-family: 'Courier New', Courier, monospace;
   max-width: 100%;
   min-height: 550px;
   border-radius: 8px;
}


#brandBanner{
    background-color: #000000;
    padding: 20px;
}
#footerb{
    font-family: "Poppins",sans-serif;
    font-weight: 550;
    font-style: normal;
    color: #333;
    padding-right:"20px";
    margin-right:'40px';
}
#DialogApplication{
    background-color: #000000;
    color: #edfff1;
    padding: "50px";
}

#Button{
    background: linear-gradient(to right,  #000000 0%, #000000 100%);
    color: wheat;
    position: relative;
    width: 100%;
    font-size: 16px;
}
#ButtonPro{
    width: 100%;
    font-size: 15px; 
    color: black;
    border: 1px solid black;
    opacity: 80%;
}
#ButtonGreen{
    width: 100%;
    font-size: 15px; 
    color: #000000;
    border: 1px solid #000000;
    opacity: 80%;
}
#head{
    font-family: 'Times New Roman', Times, serif;
    font-size: 32px;    
    margin: 20px; 
    font-weight: 36px;
}
#thead{
    margin: 20px; 
}
h4{
    margin: 25px;
    text-align: start;
   
}
#features{
    padding: 25px;
    background: linear-gradient(to right,  #000000 0%, #000000 100%);
    text-align: center;
}



.Diamond{
    background-color:#ced4da;
    border:solid  #000000 3px;

}
.VIP{
    background-color:#adb5bd ;

    border:solid  #000000 3px;
   
}
.Gold{
    color: black;
  
    
}
.Platinum{
    background-color:#ffffff;

    border:solid  #000000 3px;
}
.buttoncart{
    display: flex;
    margin-right:"10px";
}
#JoinButton{
    color: #000000;
    border: 1px solid black;
  
    padding: 8px;
   text-decoration:none;
   border-radius: 5px;
}
#JoinButton:hover{
    text-decoration: none;
}
#textD  ::after{
    bottom: 0px;
}
#orderButton{
    color: #ffffff;
    background:linear-gradient(to right,  #000000 0%, #000000 100%);
    font-size: 12px;
}
#Carddetails{
    background:#ffffff;
}
.abouthead{
    background:"#fcfcfc";
    padding: 15px;
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 90px;
}

#bannerabout{
    background: linear-gradient(to right,  #000000 0%, #000000 100%);
    padding: 50px;
    text-align: center;
    color: #edfff1;
}
#Brands{
    color: #ededed;
 font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 font-weight: 800;
}
.Tasteofpioxno{
    

   font-family: 'Courier New', Courier, monospace;
}
.Pohawala{
    
    font-family: 'Courier New', Courier, monospace;
}
.PioxnoBarista{
   
    font-family: 'Courier New', Courier, monospace;
}

.Greenfoods{
   
    font-family:'';
}
marquee{
   
font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   color: FAF3E0;
   background: linear-gradient(to right,  #000000 0%, #000000 100%);
}

marquee p{
    font-size: 18px;
}
#rpb{
    background-color: #ffffff;
    color: #000000;
    
}
#rpb p{
    font-size: 18px;
}
.newsletter{

    display:inline;
}

.PioxnoFooter{
    height: auto;
    padding: 15px;
 
    text-align: center;
}
#subscribeButton{
    border: 2px solid #000000;
    border-radius: 20px;
    background: #000000;
  padding: 12px;
  width: 90px;
}
#searchbox{
    border: 2px solid #000000;
    border-radius: 20px;
    width: 200px;
    height: 30px;
}

#app{
padding: 10px;
margin: 5px;
font-weight: 500;
font-size: small;
border: 2px solid #000000;
color: #000000;
}
.footercontent{
    padding: 15px;
    background-color: #fcfcfc;

}
.footerPioxnollp{
background-color: "#ced4da";
color: #333;
margin-top: 2px solid black;
padding: 10px;
}
.careerPage{
  font-family: 'Courier New', Courier, monospace;
}
.careerBanner{
    width: 100%;
    height: auto;
    padding: 30px;
    margin-top: 110px;
    margin-bottom: 15px;
    background-color: #f5f5f5;
}
.careertagline{
    font-size: 50px;
    font-family: 'sans-serif';
    margin-bottom: 15px;
    color: #333;
 
}
.careerDesc{
    font-size: 20px;
    font-family: 'sans-serif';
    color: #edfff1;
   
}
#jobele{
    color: #5c5a5a;
    font-size: 14px;
    font-family:Arial, Helvetica, sans-serif;
}
#jobcard{
    font-size: 14px;
    font-size: 'sans-serif';
    font-weight: 200;
}
#jobapplybutton{
 border: 1px solid rgb(0, 71, 0);
 font-size: 12px;
 color: #000000;

}
#jobapplybuttonfinal{
  
    font-size: 12px;
    color: #ffffff;
    background :linear-gradient(to right,  #000000 0%, #000000 100%);
   
   }
   #formbox:hover{
     border: 1px solid green;

   }
   #formbox{
    border-radius: 20px;
   }
a{
    color: #000000;
}
#logoD{

        font-family: "Italiana", sans-serif;
        font-weight: 400;
        font-style: normal;
   
      
}
#jobsearchTitle{
color:#013915;
font-family: sans-serif;
font-weight: 400;
font-size: 18px;
}
#joec{
    background-color: #ffffff;
 
    padding: 30px;
}
.comingsoon{
    background-color: #ededed;
    padding: 30px;
    color:rgb(127, 127, 127) ;
    
}
#htr{
   
    padding: 20px;
    border:1.5px solid black;
    border-radius: 20px;

}
/* Custom Toggle Button Styles using ID */
#custom-toggle {
    background: transparent;
    border: none;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 30px; /* Adjust if needed */
    width: 30px; /* Adjust if needed */
  }
  
  /* Bars Styles */
  #custom-toggle .bar {
    width: 100%;
    height: 2.5px;
    background-color: black;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }
  
  #custom-toggle .bar1 {
    transform: translateY(-8px); /* Adjust if needed */
  }
  
  #custom-toggle .bar2 {
    transform: translateY(0px);
  }
  
  #custom-toggle .bar3 {
    transform: translateY(8px); /* Adjust if needed */
  }
  
  /* Change appearance when toggle is active */
  #custom-toggle.collapsed .bar1 {
    transform: rotate(45deg) translateY(0px);
  }
  
  #custom-toggle.collapsed .bar2 {
    opacity: 0;
  }
  
  #custom-toggle.collapsed .bar3 {
    transform: rotate(-45deg) translateY(0px);
  }
  
  /* Hide toggle button on larger screens */
  @media (min-width: 992px) { /* Adjust breakpoint as needed */
    #custom-toggle {
      display: none;
    }
  }
  
  /* Additional Navbar Styles */
  .navbar-fixed-top {
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
  
  .navbar-brand {
    font-weight: bold;
    font-size: 24px;
  }
  
  .navbar-toggle {
    outline: none;
  }
  